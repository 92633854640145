<template>
   <card class="stacked-form" title="SMTP Email">
  <div class="col-12 text-right">
    
  </div>
  <form @submit.prevent>
    <div>
        <base-input v-model="mailer" label="Mailer" placeholder="Mailer"/>
        <validation-error :errors="apiValidationErrors.mailer" />

        <base-input v-model="host" label="Host" placeholder="Host"/>
        <validation-error :errors="apiValidationErrors.host" />
        
        <base-input v-model="port" label="Port" placeholder="Port"/> 
        <validation-error :errors="apiValidationErrors.port" /> 

        <base-input v-model="username" label="Username" placeholder="Username"/>
        <validation-error :errors="apiValidationErrors.username" />

        <base-input v-model="password" label="Password" placeholder="Password"/>
        <validation-error :errors="apiValidationErrors.password" />

        <base-input v-model="encryption" label="Encryption" placeholder="Encryption"/>
        <validation-error :errors="apiValidationErrors.encryption" />

        <base-input v-model="from_name" label="From Name" placeholder="From Name"/>
        <validation-error :errors="apiValidationErrors.from_name" />

        <base-input v-model="from_email" label="From Email" placeholder="From Email"/>
        <validation-error :errors="apiValidationErrors.from_email" />

        <base-button @click="update()" class="mt-3" native-type="submit" type="primary">Update</base-button>
    </div>
  </form>
</card>
</template>
<script>
  import ValidationError from "src/components/ValidationError.vue";
  import formMixin from "@/mixins/form-mixin";
  import axios from 'axios';

  const url = process.env.VUE_APP_API_BASE_URL;

  export default {

    components: {ValidationError},

    mixins: [formMixin],

    data: () => ({
        mailer: null,
        host: null,
        port: null,
        username: null,
        password: null,
        encryption: null,
        from_name: null,
        from_email: null
    }),
    created() {
      this.get()
    },
    methods: {
        async get() {
            try {
                const options = {
                    headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',
                    'Authorization' : `Bearer ${localStorage.getItem("access-token")}`
                    }
                };
                axios.get(`${url}/smtp-email`, options)
                    .then(response => {
                        
                        if(response.status)
                        {
                            if(response.data.smtp)
                            {
                                const smtp = response.data.smtp[0];
                                
                                this.mailer=smtp.mailer;
                                this.host=smtp.host;
                                this.port=smtp.port;
                                this.username=smtp.username;
                                this.password=smtp.password;
                                this.encryption=smtp.encryption;
                                this.from_name=smtp.from_name;
                                this.from_email=smtp.from_email;
                            }
                        }
                    });
            } catch (e) {
                
                this.$notify({
                    type: 'error',
                    message: 'Oops, something went wrong!',
                    icon: 'tim-icons icon-bell-55',
                })
            }
        },
        async update() {
            const data={
                mailer: this.mailer,
                host: this.host,
                port: this.port,
                username: this.username,
                password: this.password,
                encryption: this.encryption,
                from_name: this.from_name,
                from_email: this.from_email
            }
            const options = {
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',
                    'Authorization' : `Bearer ${localStorage.getItem("access-token")}`
                }
            };
            try {
                axios.post(`${url}/smtp-email`, data, options)
                    .then(response => {
                        if(response.data)
                        {
                            if(response.data.status)
                            {
                                this.$notify({
                                    type: 'success',
                                    message: 'SMTP Updated successfully',
                                    icon: 'tim-icons icon-bell-55',
                                }) 
                            }
                            else
                            {
                                this.$notify({
                                    type: 'danger',
                                    message: response.data.message,
                                    icon: 'tim-icons icon-bell-55',
                                }) 
                            }
                        }
                    }).catch(error => {
                        this.$notify({
                            type: 'danger',
                            message: 'Oops, something went wrong!',
                            icon: 'tim-icons icon-bell-55',
                        })
                    });
            } catch (e) {
                
                this.$notify({
                    type: 'danger',
                    message: 'Oops, something went wrong!',
                    icon: 'tim-icons icon-bell-55',
                })
            }
        }
    }
  }
</script>
    