<template>
<div class="loginSection__Inner">
  <div class="container">
    <div class="header-body text-center">
        <div class="row justify-content-center">
          <div class="col-lg-5 mx-auto">
            <div class="text-center">
              <a href="/"><img class="formLogo" src="/img/fuse-logo.svg" /></a>
            </div>
            <h1 class="text-login formHeadingText">Forgot my password</h1>
              <!-- <div class="text-center"  style="margin-bottom: 5px;">
              </div> -->

              <!-- <div class="text-login">
                <h3 class="text-login"><strong>You can log in with 3 user types:</strong></h3>
                <div>Username <b>admin@jsonapi.com</b> Password <b>secret</b></div>
                <div>Username <b>creator@jsonapi.com</b> Password <b>secret</b></div>
                <div>Username <b>member@jsonapi.com</b> Password <b>secret</b></div>
              </div> -->

              <!-- <div class="googleBtn">
                <button class="btn btnGoogleLogin"> <span><img src="/img/googleIcon.png" /></span>Sign in with Google</button>
                <p>Or</p>
              </div> -->
          </div>
        </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-6 mx-auto">
        <form ref="reset_password_form" @submit.prevent="handleSubmit()" class="loginForm">
            <template slot="header">
              <!-- <img src="/img/card-primary.png" alt="" /> -->
              <h1 class="card-title">Reset my password</h1>
            </template>
            <base-input
                required
                v-model="email"
                type="email"
                placeholder="Email"
                addon-left-icon="tim-icons icon-email-85"
            />
            <validation-error :errors="apiValidationErrors.email" />

            <div class="text-center">
              <base-button type="primary" native-type="submit" class="w-100 startBtn">
              Send Password Reset Link
              </base-button>
            </div>
            
            <router-link class="btn w-100 startBtn btn-primary" v-show="login" to="/login" style="display:none;">
                  Go Back to Login
              </router-link>
        </form>
      </div>
    </div>
  </div>
  
</div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
export default {
  layout: "AuthLayout",
  mixins: [formMixin],
  components: { ValidationError },
  data() {
    return {
      login:false,
      email:'',
      data: {
        email: ""        
      },
    };
  },
  methods: {
    async handleSubmit() {
      const data = {
        email: this.email
      };
      if (this.$isDemo == 1) {
        await this.$notify({
          type: "danger",
          message: "Password reset is disabled in the demo.",
        });
        return;
      }
      try {        
        await this.$store.dispatch("reset/forgotPassword", data.email);
        await this.$notify({
          type: "success",
          message: "An email with reset password link was sent.",
        });
        this.login=true
        this.$refs['reset_password_form'].reset()
      } catch (error) {
        await this.$notify({
          type: "danger",
          message: error.response.data.message,
        });
        this.setApiValidation(error.response.data.message);
      }
    },
  },
};
</script>
