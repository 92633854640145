<template>
    <card class="stacked-form" title="SMTP Email">
   <div class="col-12 text-right">
     
   </div>
   <form @submit.prevent>
     <div>
        <base-input type="password" v-model="password" label="Password" placeholder="Password"/>
        <validation-error :errors="apiValidationErrors.password" />

        <base-input type="password" v-model="password_confirmation" label="Password Confirmation" placeholder="Password Confirmation"/>
        <validation-error :errors="apiValidationErrors.password_confirmation" />

        <base-button @click="update()" class="mt-3" native-type="submit" type="primary">Update</base-button>
     </div>
     
   </form>
 </card>
 </template>
 <script>
   import ValidationError from "src/components/ValidationError.vue";
   import formMixin from "@/mixins/form-mixin";
   import axios from 'axios';
 
   const url = process.env.VUE_APP_API_BASE_URL;
 
   export default {
 
     components: {ValidationError},
 
     mixins: [formMixin],
 
     data: () => ({
         password: null,
         password_confirmation: null
     }),
     
     methods: {
         async update() {
             const data={
                 password: this.password,
                 password_confirmation: this.password_confirmation
             }
             const options = {
                 headers: {
                     'Accept': 'application/vnd.api+json',
                     'Content-Type': 'application/vnd.api+json',
                     'Authorization' : `Bearer ${localStorage.getItem("access-token")}`
                 }
             };
             try {
                 axios.post(`${url}/reset-password`, data, options)
                     .then(response => {
                         if(response.data)
                         {
                             if(response.data.status)
                             {

                                 this.$notify({
                                     type: 'success',
                                     message: 'Password Updated successfully',
                                     icon: 'tim-icons icon-bell-55',
                                 }) 

                             }
                             else
                             {
                                 this.$notify({
                                     type: 'danger',
                                     message: response.data.message,
                                     icon: 'tim-icons icon-bell-55',
                                 }) 
                             }
                         }
                     }).catch(error => {
                         this.$notify({
                             type: 'danger',
                             message: 'Oops, something went wrong!',
                             icon: 'tim-icons icon-bell-55',
                         })
                     });
             } catch (e) {
                 
                 this.$notify({
                     type: 'danger',
                     message: 'Oops, something went wrong!',
                     icon: 'tim-icons icon-bell-55',
                 })
             }
         }
     }
   }
 </script>
     