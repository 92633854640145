<template>
<div class="loginSection__Inner">
  <div class="container">
    <div class="header-body text-center">
        <div class="row justify-content-center">
          <div class="col-lg-5 mx-auto">
            <a href="/"><img class="formLogo" src="/img/fuse-logo.svg" /></a>
            <h1 class="text-login formHeadingText">Log into my account</h1>
              <!-- <div class="text-center"  style="margin-bottom: 5px;">
              </div> -->

              <!-- <div class="text-login">
                <h3 class="text-login"><strong>You can log in with 3 user types:</strong></h3>
                <div>Username <b>admin@jsonapi.com</b> Password <b>secret</b></div>
                <div>Username <b>creator@jsonapi.com</b> Password <b>secret</b></div>
                <div>Username <b>member@jsonapi.com</b> Password <b>secret</b></div>
              </div> -->

              <!-- <div class="googleBtn">
                <button class="btn btnGoogleLogin" @click="googleLogin()"> <span><img src="/img/googleIcon.png" /></span>Sign in with Google</button>
                <p>Or</p>
              </div> -->
          </div>
        </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-6 mx-auto">
        <form @submit.prevent="handleSubmit()" class="loginForm">
          <!-- <card class="card-login card-white"> -->
            <template slot="header">
              <!-- <img src="/img/card-primary.png" alt="" /> -->
              <h1 class="card-title">Log in</h1>
            </template>

            <div>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
              >
              <base-input
                required
                v-model="email"
                type="email"
                placeholder="Email"
                addon-left-icon="tim-icons icon-email-85"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
              <validation-error :errors="apiValidationErrors.email" />
              </ValidationProvider>

              <ValidationProvider
                name="password"
                rules="required|min:5"
                v-slot="{ passed, failed, errors }"
              >
              <base-input
                required
                v-model="password"
                placeholder="Password"
                addon-left-icon="tim-icons icon-lock-circle"
                type="password"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
              <validation-error :errors="apiValidationErrors.password" />
            </ValidationProvider>
            </div>

            <div slot="footer">
              <base-button native-type="submit" type="primary" class="mb-3 startBtn" size="lg" block>
                Login
              </base-button>
              <div class="pull-left">
                <h6>
                  <router-link class="link footer-link" to="/register">
                    Create Account
                  </router-link>
                </h6>
              </div>

              <div class="pull-right">
                <h6><a href="/password/reset" class="link footer-link">Forgot Password?</a></h6>
              </div>
            </div>
          <!-- </card> -->
        </form>
        <!-- </ValidationObserver> -->
      </div>
    </div>
  </div>
</div>
</template>
<script>

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";

extend("email", email);
extend("min", min);

extend("required", required);

export default {
  mixins: [formMixin],
  components: {
    ValidationError,
  },
  data() {
    return {
      subscribe: true
    };
  },
  methods: {
      async handleSubmit() {
        const user =  {
          email: this.email,
          password: this.password
        }

        const requestOptions = {
          headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
          }
        }
        
          const result= await this.$store.dispatch("login", {user, requestOptions})
          if(result.status==401)
          {      
              this.$notify({
                message:result.data.message,
                icon: 'tim-icons icon-bell-55',
                type: 'danger'
              });
              this.setApiValidation(e.response)

          }

    },
   
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
